import { render, staticRenderFns } from "./Default.vue?vue&type=template&id=496c3e67"
import script from "./Default.vue?vue&type=script&lang=js"
export * from "./Default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports